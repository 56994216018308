<template>
<v-container class="form-container">
</v-container>
</template>

<script>
export default {
    props: {
        projectId: {
            type: String,
            default: '',
        },
    },
    data() {
        return {
            phone: '',
            text: '',
        };
    },
    created() {
        this.getInstace();
    },
    methods: {
        async getInstace() {
            const url = new URL('https://sparkprize.azurewebsites.net/api/v1/WaapiInstance/rotator/' + this.projectId);

            try {
                const response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json'
                    }
                });

                if (response.ok) {
                    var data = await response.json()
                    this.phone = data.Instance.Phone;
                    this.text = data.Intent;
                    this.sendWhatsAppMessage(this.phone, this.text);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        },
        sendWhatsAppMessage(phone, text) {
            const baseUrl = "https://api.whatsapp.com/send/";
            const url = `${baseUrl}?phone=${phone}&text=${text}&type=phone_number&app_absent=0`;
            window.location.href = url;
        }
    },
};
</script>
